*,
*::after,
*::before {
	box-sizing: border-box;
}

body {
	font-family: "Bubblegum Sans", cursive;
	text-align: center;
	margin: 0;
	padding: 0;
	background: linear-gradient(
		135deg,
		#5ef2a6 30%,
		#ffffff 30%,
		#ffffff 70%,
		#f25e60 70%
	);
	background-size: 100vw 100vh;
	background-repeat: no-repeat;
}

.main {
	position: absolute;
	left: 50%;
	top: 45%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
}

div {
	font-size: 28px;
	color: #5d5fef;
	margin-bottom: 50px;
}

img {
	width: 250px;
}

@media only screen and (max-width: 600px) {
	img {
		width: 180px;
	}
}
